import React from 'react'

import {Link} from "react-router-dom";

const PageFooter = () => {

  return (
    <>
    <div className='footer'>
      <div className='box'>
        <h4>Navigation</h4>
        <ul>
          <li><Link to="/">Home</Link></li>
          <li><Link to="/projects">Projects</Link></li>
          <li><Link to="/contact">Contact</Link></li>
        </ul>
      </div>
      <div className='box'>
      <h4>Connect</h4>
        <ul>
          <li><a href="https://www.facebook.com/profile.php?id=100088787626282" target="blank">Facebook</a></li>
          <li><a href="https://instagram.com/ivoryautomotive.ie" target="blank">Instagram</a></li>
          <li></li>
        </ul>
      </div>
    </div>
    <div className='creatorFooter'>Website by Oisín Ivory</div>
    </>
  );
};

export default PageFooter;