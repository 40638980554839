import React from 'react';
import {Link} from "react-router-dom";
const NavButton = (props) => {
  return (
    <>
     <Link to={props.destination}>
        <div className='button'>
        {props.name}
        </div>
    </Link> 
    </>
  );
};

export default NavButton;