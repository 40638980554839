import React from "react";
import Carousel from "../../components/carousel";

const HomePage = () => {

  return (
    <>
    <div className="content">
        <div className="imageDisplay">
          <div>
            <h1>Ivory Automotive</h1> <p>Waterford</p>
          </div>
        </div>
        <div className="textDisplay"> 
          <div className="text">
          <h1>Ivory Automotive</h1>
          A company that prides itself in meticulous, high quality restorations and builds.
          </div>
        </div>
        <Carousel/>
    </div>
    </>
  );
};

export default HomePage;