import React from "react";
import projects from "../../data/projects"

import ProjectDisplay from "../../components/projectsDisplay"
const ProjectsPage = () => {

    const localProj = projects
    
    return (
        <>
        <div className="content">
            <div className="projectsHeader"><h1>Projects</h1></div>
            <ProjectDisplay/>
        </div>
        </>
    );
};

export default ProjectsPage;