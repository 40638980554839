import './App.css';

import PageHeader from './components/pageHeader/'
import PageFooter from './components/pageFooter/'
import { BrowserRouter, Route, Routes} from "react-router-dom"

import HomePage from './pages/home';
import ContactPage from './pages/contact';
import ProjectsPage from './pages/projects';
import ProjectPage from './pages/project';


import ScrollToTop from './components/scrollToTop';

function App() {
  return (
    <BrowserRouter>
      <PageHeader/>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<HomePage/>} />
        <Route path="/contact" element={<ContactPage/>} />
        <Route path="/projects" element={<ProjectsPage/>}/>
        <Route path="/project/:id" element={<ProjectPage />}/>
        <Route path="*" element={<HomePage/>}/>
      </Routes>
      <PageFooter/>
    </BrowserRouter>
  );
}

export default App;
