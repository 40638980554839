import React from "react";
import { useParams } from "react-router-dom";
import { useState,useCallback } from "react";

import projects from "../../data/projects"
import ImageViewer from 'react-simple-image-viewer'
const ProjectPage = () => {
    let { id } = useParams();
    const project = projects[id]

    

    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);

    function imageList(project){
        let paths = []
        project.images.forEach(image => {
            paths.push("../images/projects/"+project.id+"/"+image)
        });
        return paths
    }

    const images = imageList(project)
    
    const openImageViewer = useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };
    
    return (
        <>
        <div className="content">
            <div className="projectDiv">
                <div className="projectImage"><img src={"../images/projects/"+project.id+"/"+project.images[0]}></img></div>
                <div className="projectText">
                <div>
                    <h1>{project.name}</h1>
                    {project.description.map((txt, index) => (
                        <p>{txt}</p>
                    ))}
                    </div>
                    </div>
            </div>
            <div className="headerBanner">Gallery</div>
            <div className="projectDivImages">
                {images.map((src, index) => (
                    <div className="imageDiv">
                        <img
                        src={ src }
                        onClick={ () => openImageViewer(index) }
                        key={ index }
                        />
                    </div>
            ))}

</div>
            {isViewerOpen && (
                <div className="imageViewer">
                <ImageViewer
                src={ images }
                currentIndex={ currentImage }
                disableScroll={ false }
                closeOnClickOutside={ true }
                onClose={ closeImageViewer }
                />
                </div>
            )}

        </div>
        </>
    );
};

export default ProjectPage;