import React from 'react';
const contactBlock = (props) => {
  
  return (
    <>
     <div className='contactBlock'>
        <div className='image'><img src={props.image}></img></div>
        <div className='text'>{props.type}</div>
        <div className='text'>{props.data}</div>
    </div>
    </>
  );
};

export default contactBlock;