const data = [
    {
        "id":0,
        "name":"Series II A Fire Engine",
        "make":"Land Rover Series II A",
        "description":[
            "This was a complete retoration for a client in Cork.",
            "The entire land rover was stripped down to nuts and bolts, before being cleaned, repaired, and repainted.",
            "The chasis was welded up and galvanised, the axels completely rebuild along with the engine and transmission",
            "Every panel was straightened and repainted, decals were printed off and fitted to the panels."
        ],
        "images":[
            "1after.jpg",
            "2before.jpg",
            "3after.jpg",
            "4before.jpg",
            "5after.jpg",
            "6before.jpg",
            "7after.jpg",
            "8before.jpg",
            "9after.jpg",
            "abefore.jpg",
        ]
    },
    {
        "id":1,
        "name":"Chassis Repairs",
        "make":"Land Rover Series II A",
        "description":[
            "If you're ever too busy and don't have the time to repair parts, feel free to ring and we can help you out.",
            "After having worked with a vast array of different parts throughout many projects you can guarantee that it will be done to the highest standard."
        ],
        "images":[
            "1.jpg",
            "2.jpg",
            "3.jpg",
            "4.jpg",
            "5.jpg",
            "6.jpg",
            "7.jpg",
            "8.jpg"
        ]
    },
    {
        "id":2,
        "name":"101 Forward Control",
        "make":"Land Rover 101 Forward Control",
        "description":[
            "This was a personal project which is still in the works. As usual it was a complete strip down before repairing, straightning, and painting the many different parts.",
            "With only around 2,700 produced for the military it was important to bring this back up to par with the other projects that have been done.",
            "The goal is to get it to the point where it is just as it was rolling off the production line."
    ],
        "images":[
            "1.jpg",
            "2.jpg",
            "3.jpg",
            "4.jpg",
            "5.jpg",
            "6.jpg",
            "7.jpg",
            "8.jpg",
            "9.jpg"
        ]
    },
    {
        "id":3,
        "name":"Land Rover Series IIA",
        "make":"Land Rover Series IIA",
        "description":[
            "This was a good project to do, when I first got it it was a mix of series III and II parts, along with parts that didn't match.",
            "It was completely tore down and I sourced the parts to try bring it back to the original as much as I could. This involved removing"+
             "the thick layer of paint that it was covered in. Like other projects the axels and everything was tore down, repair and painted.",
            "While it's not 100% a series II, it certianly looks the part."
        ],
        "images":[
            "1.jpg",
            "2.jpg",
            "3.jpg",
            "4.jpg",
            "5.jpg",
            "6.jpg",
            "7.jpg",
            "8.jpg",
            "9.jpg",
            "a.jpg"
        ]
    },
    {
        "id":4,
        "name":"Defender 90",
        "make":"Land Rover Defender",
        "description":[
            "This Td5 90 was in for a new lease of life, we supplied and fitted a new galvanised chassis, along with replacing all bushes, brake hoses and Brake lines. Some preventive maintenance was also carried out with a new intercooler, also replaced rear axle back exhaust.", 
            "With all new bushes, and a fresh coat of paint on the axles and arms, finishes this job nicely."
        ],
        "images":[
            "1.jpg",
            "2.jpg",
            "3.jpg",
            "4.jpg",
            "5.jpg",
            "6.jpg",
            "7.jpg",
            "8.jpg",
            "9.jpg",
            "10.jpg",
            "11.jpg",
            "12.jpg"
        ]
    }
    

]

export default data