const slideshows = [
    {
        id:0,
        image:"images/home/restoration.jpg",
        header:"Restoration",
        text:"We offer many different restoration services, ranging from complete rebuilds to parts such as chassis and bulkhead repairs."
    },
    {
        id:1,
        image:"images/home/parts.jpg",
        header:"Parts",
        text:"If you're having trouble finding a certain part or just looking for some in general, feel free to contact us and we can take a look at our inventory to try help you out."
    },
    {
        id:2,
        image:"images/home/service.jpg",
        header:"Servicing",
        text:"We also offer servicing, we'll give your vehicle a lookover, top up any fluids, and check the condition of parts."
    }
]   

export default slideshows