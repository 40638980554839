import React from 'react';

import ProjectCard from '../projectCard'
import data from "../../data/projects.js";

const Carousel = () => {
  const content = data;

  return (  
    <>
    <div className='projectDisplay'>
        {content.map((item)=>(
            <ProjectCard project={item} key={item.id}/>
        ))}
    </div>
    </>
  );
};

export default Carousel;