import React from 'react';
import { Link } from 'react-router-dom';
import NavButton from '../navButton';
const PageHeader = () => {

  return (
    <>
    <div className="navbar">
    <Link to="/"><img className ="logo" src="logo_transparent.png"></img></Link>
    <NavButton name="Home" destination="/"/>
    <NavButton name="Projects" destination="/projects"/>
    <NavButton name="Contact" destination="/contact"/>
    </div>
    </>
  );
};

export default PageHeader;