import React from 'react';

import data from "../../data/slideshow.js";
import CarouselCard from "../carouselCard"

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from "swiper";

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';  

import SwiperCore, { Pagination } from 'swiper';
import 'swiper/swiper-bundle.css';

const Carousel = () => {

  SwiperCore.use([Pagination]);
  const content = data;

  return (  
    <>
    <div className='carousel'>
    <Swiper
    navigation={true} modules={[Navigation,Pagination,Autoplay]}
    autoplay={{
      delay: 5000,
      disableOnInteraction: false,
    }}
      spaceBetween={50}
      slidesPerView={1}
    >
      {content.map((item)=>(
      <>
        <SwiperSlide><CarouselCard 
        key={item.id}
        image={item.image} 
        header={item.header} 
        text={item.text}/>
        </SwiperSlide>
      </>
    ))}
    </Swiper>
    </div>
    {/* <div className='carousel'>
    {content.map((item)=>(
      <>
        <CarouselCard 
        key={item.id}
        image={item.image} 
        header={item.header} 
        text={item.text}/>
      </>
    ))}
    </div> */}
    </>
  );
};

export default Carousel;