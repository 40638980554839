import React from 'react';
const NavButton = (props) => {
  
  return (
    <>
     <div className='card'>
        <div className='text'><p className='header'>{props.header}</p>{props.text}</div>
        <div className='image'><img src={props.image}></img></div>
    </div>
    </>
  );
};

export default NavButton;