import React from 'react';

import { Link } from 'react-router-dom';

const ProjectCard = (props) => {
  const project = props.project
  return (
    <>
  
  <Link to={`/project/${project.id}`} className="viewMore">
     <div className='projectCard'>
      
        <div className='wrapper'>
          <div className='cover'>
          <div className='content'>{project.name}</div></div>
          <div className='image'><img src={"images/projects/"+project.id+"/"+project.images[0]}></img></div>
        </div>
    </div>
    </Link>
    </>
  );
};

export default ProjectCard;