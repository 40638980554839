import React from "react";

import ContactBlock
 from "../../components/contactBlock";
const ContactPage = () => {
  
  return (
    <>
    <div className="content">
      <div className="content">
        <div className="projectsHeader"><h1>Contact</h1></div>
        <div className="contactText">
          To make inquires feel free to contact Aaron at
        </div>
        <div class="contactBlockContianer">
        <ContactBlock type="Email" data="ivoryautomotive@gmail.com" image="images/contact/email.png"/>
        <ContactBlock type="Phone" data="087 689 5119" image="images/contact/phone.png"/>

        </div>
      </div>
    </div>
    </>
  );
};

export default ContactPage;